<template>
    <columns>
        <column>
            <h1 class="title">
              {{ heading }}
            </h1>
            <h6 class="subtitle is-6 has-text-grey">
                <span v-if="subHeading">{{ subHeading }}</span>
                <slot v-else />
            </h6>
        </column>
    </columns>
</template>
<script>
export default {
    props: {
        heading: String,
        subHeading: String,
    }
    }
</script>